@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$primary-color: #0a1b43;
$secondary-color: #5381fb;
$tertiary-color: #00efc9;
$warning-color: #fdca14;
$grey-color: #c4ccd8;
$text-color: #3b4969;
$withe-color: #ffffff;
$bg-color: #ffffff;
$nav-size: 60px;
$padding-layout: 60px;
$prefix: 'imd';
$border-radius: 3px;
$font-family: 'Roboto', sans-serif;
// Desktops 1281px to higher resolution desktops
$screen-xl-min: 1281px;

//Laptops, Desktops
$screen-lg-min: 1025px;
$screen-lg-max: 1280px;

// Tablets, Ipads (portrait) (landscape)
$screen-md-min: 768px;
$screen-md-max: 1024px;

// Table Tabletas de baja resolución (Landscape)
$screen-md-ls-min: 481px;
$screen-md-ls-max: 767px;

// Smartphones
$screen-sm-min: 320px;
$screen-sm-max: 480px;
