html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: $font-family;
}
.imd-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: auto;
  height: 80vh;
  & .imd-parrafo {
    font-weight: 500;
    font-size: 15px;
    color: $text-color;
  }
  & svg {
    width: 100%;
    height: auto;
  }
}

.imd-contact-form-container {
  background-color: $withe-color;
  border-radius: $border-radius;
  display: flex;
  justify-content: center;
  justify-items: center;
  flex-direction: column;
  width: 80%;
  padding: $padding-layout / 3;
  & .imd-title {
    width: 100%;
  }
  & .imd-parrafo {
    width: 100%;
  }
  .imd-redes-contact {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0.5rem 1rem;
    & .item-redes-contact {
      &:not(:last-child) {
        margin-right: 20px;
      }

      & a {
        & svg {
          display: inline-block;
          width: 38px;
          height: 38px;
        }
      }
    }
  }

  .item-form-contact {
    display: flex;
    flex-direction: column;
    text-align: left;
    & form {
      & .form-control {
        display: flex;
        flex-direction: column;
        padding: 0.5rem;
        & label {
          font-size: 14px;
          color: $primary-color;
          font-weight: 500;
          padding-bottom: 2px;
          & small {
            display: block;
            color: $text-color;
            font-size: 12px;
            font-weight: 100;
          }
        }
        & input,
        textarea {
          outline: none;
          border: 1px solid $grey-color;
          padding: 2px 4px;
          height: 25px;
          &:focus {
            border-color: $secondary-color;
          }
        }
        & textarea {
          min-height: 50px;
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 812px) {
    width: fit-content;
    margin: 0;
  }
}

.imd-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: $padding-layout;
  background-color: $primary-color;
  color: $withe-color;

  & .imd-footer-item {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 1rem;
    & img {
      width: 130px;
      display: block;
    }
    & p {
      padding: 0;
      margin-bottom: 10px;
    }
    & small {
      margin-bottom: 10px;
    }
    & h1 {
      font-size: 15px;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
    & .imd-footer-list {
      list-style: none;
      padding: 0;
      & li {
        margin-bottom: 10px;
        & a {
          text-decoration: none;
          color: $withe-color;
        }
      }
    }
    & .imd-footer-redes {
      display: flex;

      & .item-footer-rede {
        &:not(:last-child) {
          margin-right: 20px;
        }
        & a {
          & svg {
            display: inline-block;
            width: 35px;
            height: 35px;
          }
        }
      }
    }
  }
  @media (min-width: 320px) and (max-width: 812px) {
    padding: $padding-layout / 3;
  }
}

.swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: $primary-color;
  font-size: 12px;
  border: 1px solid $primary-color;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
  &:hover {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }
}

.imd-fixed-whats-app {
  position: fixed;
  bottom: $padding-layout / 3;
  right: $padding-layout / 3;
  & svg {
    width: 35px;
    height: 35px;
  }
}
