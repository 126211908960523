@import './variables';

@mixin section-layout($padding: $padding-layout) {
  padding: $padding;
}

@mixin grid-layout($column: repeat(2, 1fr), $padding: $padding-layout, $align: center) {
  padding: $padding;
  grid-template-columns: $column;
  align-items: $align;
}

@mixin align-col-items($content: center, $align: center, $fd: column, $text: center) {
  display: flex;
  justify-content: $content;
  align-items: $align;
  flex-direction: $fd;
  text-align: $text;
}

.imd-column-1 {
  @include section-layout;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imd-column-2 {
  display: grid;
  @include grid-layout;
  & .imd-col-description {
    @include align-col-items;
  }
  & .imd-col-image {
    @include align-col-items;
  }
}

.imd-column-even-odd {
  display: grid;
  @include grid-layout;
}

.imd-column-price {
  @include section-layout;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .imd-column-1 {
    padding: $padding-layout;
  }
  .imd-column-2 {
    @include grid-layout($align: center);
    & .imd-col-description {
      @include align-col-items($text: left, $align: flex-start);
      & .imd-title {
        width: 80%;
      }
    }
    & .imd-col-image {
      @include align-col-items;
    }
  }
  .imd-column-even-odd {
    @include grid-layout($column: repeat(2, 1fr), $padding: $padding-layout, $align: center);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;
      & .imd-title {
        width: 100%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
      & .imd-title {
        width: 100%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .imd-column-1 {
    padding: $padding-layout;
  }

  .imd-column-2 {
    @include grid-layout($padding: $padding-layout, $align: center);
    & .imd-col-description {
      @include align-col-items($text: left, $align: flex-start);
      & .imd-title {
        width: 80%;
      }
    }
    & .imd-col-image {
      @include align-col-items;
    }
  }

  .imd-column-even-odd {
    @include grid-layout($column: repeat(2, 1fr), $padding: $padding-layout, $align: start);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;
      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .imd-column-1 {
    padding: $padding-layout / 3;
  }

  .imd-column-2 {
    @include grid-layout($padding: $padding-layout / 3);
    & .imd-col-description {
      @include align-col-items($text: left, $align: flex-start);
      & .imd-title {
        width: 80%;
      }
    }
    & .imd-col-image {
      @include align-col-items;
    }
  }

  .imd-column-even-odd {
    @include grid-layout($column: repeat(2, 1fr), $padding: $padding-layout / 3, $align: start);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;
      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .imd-column-1 {
    padding: $padding-layout / 3;
  }

  .imd-column-2 {
    @include grid-layout($padding: $padding-layout / 3, $align: center);
    & .imd-col-description {
      @include align-col-items($text: left, $align: flex-start);
      & .imd-title {
        width: 80%;
      }
    }
    & .imd-col-image {
      @include align-col-items;
    }
  }

  .imd-column-even-odd {
    @include grid-layout($column: repeat(2, 1fr), $padding: $padding-layout / 3, $align: start);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;

      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
      & .imd-title {
        width: 90%;
      }
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .imd-column-1 {
    padding: $padding-layout / 3;
  }

  .imd-column-2 {
    @include grid-layout($column: repeat(1, 1fr), $padding: $padding-layout / 3);
    & .imd-col-description {
      @include align-col-items($text: center);
    }
    & .imd-col-image {
      @include align-col-items;
    }
  }

  .imd-column-even-odd {
    @include grid-layout($column: repeat(1, 1fr), $padding: $padding-layout / 3, $align: start);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .imd-column-1 {
    padding: $padding-layout / 3;
  }

  .imd-column-2 {
    @include grid-layout($column: repeat(1, 1fr), $padding: $padding-layout / 3);
    & .imd-col-description {
      @include align-col-items($text: center);
    }
    & .imd-col-image {
      @include align-col-items;
    }
    & .imd-contact-description {
      display: none;
    }
  }

  .imd-column-even-odd {
    @include grid-layout($column: repeat(1, 1fr), $padding: $padding-layout / 3, $align: start);
  }

  // par
  .imd-column-even-odd:nth-child(even) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-left: 3rem;
    }
    & .imd-col-image {
      @include align-col-items($content: flex-end, $align: flex-end);
    }
  }
  // inpar
  .imd-column-even-odd:nth-child(odd) {
    & .imd-col-description {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
      padding-right: 3rem;
    }
    & .imd-col-image {
      @include align-col-items($content: flex-start, $align: flex-start, $text: left);
    }
  }

  .imd-column-price {
    @include section-layout($padding: $padding-layout / 3);
  }
}
