@mixin grid-table-price($column: 40% 20% 20% 20%, $align: center) {
  display: grid;
  grid-template-columns: $column;
  align-items: $align;
  padding: 0.5rem;
  font-size: 14px;
}

.imd-legend-table-price {
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0.5rem;
  padding-left: 0.5rem;
  & span {
    display: flex;
    align-items: center;
    // text-align: center;
    & svg {
      display: inline !important;
      padding-right: 4px;
    }
  }
}

.imd-price-table-container {
  width: 100%;
  background-color: $withe-color;
  border-radius: $border-radius;
  overflow: hidden;
  padding: 1rem;
  margin: 0.5rem;

  &.imd-stycky-header {
    position: sticky;
    top: $nav-size;
    z-index: 10;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  & .imd-price-table-row {
    @include grid-table-price;
    &.imd-prices-table-title {
      color: $primary-color;
      font-weight: 600;
    }
    &.imd-prices-table-prices {
      color: $secondary-color;
      & .imd-title-price {
        &:nth-child(2) {
          text-decoration: line-through;
        }
        &:nth-child(3) {
          text-decoration: line-through;
        }
      }
    }
    &.imd-prices-table-offer {
      color: $warning-color;
      font-weight: 600;
      justify-content: center;
    }

    & .imd-column-table {
      color: $primary-color;
      & svg {
        display: inline !important;
      }
    }
  }
}

.imd-card-table-movile {
  box-shadow: 0 0 15px 0 #d1dee8;
  padding: 1rem;
  margin: 0.5rem;
  & .imd-card-table-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    font-weight: 600;

    & .imd-title-prices {
      color: $primary-color;
      margin-bottom: 0.5rem;
    }

    & .imd-title-price {
      color: $secondary-color;
      margin-bottom: 0.5rem;
      &:nth-child(2) {
        text-decoration: line-through;
        font-size: 14px;
      }
    }

    & .imd-title-offer {
      color: $warning-color;
      margin-bottom: 0.5rem;
      font-size: 18px;
    }
  }

  & .imd-card-table-body {
    display: grid;
    grid-template-columns: 70% 30%;
    font-size: 12px;
    width: 100%;
    & .imd-card-body-item-title {
      padding: 6px 4px;
    }
    & .imd-card-body-item {
      padding: 6px 4px;
    }
    & .imd-card-body-item-validate {
      display: flex;
      align-items: center;
      justify-content: center;
      & svg {
        display: inline !important;
      }
    }
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
  .imd-card-table-movile {
    display: none;
  }
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
  .imd-card-table-movile {
    display: none;
  }
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
  .imd-card-table-movile {
    display: none;
  }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .imd-card-table-movile {
    display: none;
  }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
  .imd-d-none-sm {
    display: none;
  }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .imd-d-none-sm {
    display: none;
  }
  .imd-price-table-container {
    & .imd-price-table-row {
      display: none;
    }
  }
}
