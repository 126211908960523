@import 'variables';

@mixin layout-title($fs: 38px, $wd: 50%) {
  font-size: $fs;
  font-weight: 700;
  font-family: $font-family;
  width: $wd;
  margin: 0.5rem;
}

@mixin layout-paragraph($color: $text-color, $fs: 14px, $wd: 50%) {
  color: $color;
  font-size: $fs;
  width: $wd;
  margin: 0.5rem;
}

@mixin layout-image-box($wd: 80%) {
  width: $wd;
  margin: 0.5rem;
}

.imd-link {
  text-decoration: none;
  color: $text-color;
  &:hover {
    color: $secondary-color;
  }
}

.imd-title {
  @include layout-title;
}

.imd-parrafo {
  @include layout-paragraph;
}

.imd-image-box {
  @include layout-image-box;
  & svg {
    width: 100%;
    height: 100%;
  }
}

.imd-play-button {
  display: flex;
  align-items: center;
  margin: 0.5rem;
  cursor: pointer;
  & svg {
    fill: $secondary-color;
    width: 40px;
    height: 40px;
  }
  & span {
    margin-left: 10px;
    font-weight: 700;
    font-family: 24;
    color: $tertiary-color;
  }
}

// divider
.imd-divier-o {
  display: flex;
  align-items: center;
  color: $grey-color;
  & hr {
    margin-left: auto;
    margin-right: auto;
    width: 40%;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px dotted $grey-color;
  }
}
// font-size
.imd-fs-xl {
  font-size: 30px !important;
  font-weight: 700;
}
.imd-fs-sm {
  font-size: 11px;
}
// font-weight
.imd-fw-ligth {
  font-weight: 100;
}
.imd-fw-medium {
  font-weight: 500;
}
.imd-fw-regular {
  font-weight: 600;
}
.imd-fw-bold {
  font-weight: 700;
}
// text-transform
.imd-text-uppercase {
  text-transform: uppercase;
}
.imd-text-lowercase {
  text-transform: lowercase;
}
.imd-text-capitalize {
  text-transform: capitalize;
}
// bg
.imd-bg-primary {
  background-color: $primary-color;
}
.imd-bg-secondary {
  background-color: $secondary-color;
}
.imd-bg-tertiary {
  background-color: $tertiary-color;
}
.imd-bg-grey {
  background-color: $grey-color;
}
.imd-bg-opacity {
  background-color: #c4ccd82e;
}
.imd-bg-gey-1 {
  background-color: #f6f9fd;
}
// color
.imd-text-info {
  color: $secondary-color;
}
.imd-text-success {
  color: $tertiary-color;
}
.imd-text-primary {
  color: $primary-color;
}
.imd-text-white {
  color: $withe-color;
}
.imd-text-color {
  color: $text-color !important;
}
.imd-text-warning {
  color: $warning-color;
}
// align text
.imd-text-center {
  text-align: center;
}
.imd-text-right {
  text-align: right;
}
.imd-text-left {
  text-align: left;
}

.imd-text-justify {
  text-align: justify;
}
// separator
.imd-separator {
  border-bottom: 1px dotted $grey-color;
}
.imd-border-top {
  border-top: 1px dashed $secondary-color;
}
.imd-border-bottom {
  &:not(:last-child) {
    border-bottom: 1px dashed $secondary-color;
  }
}
// padding
.imd-pt-0 {
  padding-top: 0 !important;
}
.imd-pl-1 {
  padding-left: 1rem;
}
.imd-pl-2 {
  padding-left: 2rem;
}
.imd-pt-2 {
  padding-top: 2rem;
}
.imd-pb-2 {
  padding-bottom: 2rem;
}
// margin
.imd-m-0 {
  margin: 0 !important;
}

.imd-mt-0 {
  margin-top: 0 !important;
}
.imd-mt-2 {
  margin-top: 2rem;
}
.imd-mb-2 {
  margin-bottom: 2rem;
}
.imd-mt-4 {
  margin-top: 4rem;
}
.imd-mb-4 {
  margin-bottom: 4rem;
}
// Lista
.imd-list-solution {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  & li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 12px;
    color: $text-color;
    & span {
      padding-left: 3px;
    }
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .imd-title {
    @include layout-title($fs: 20px, $wd: 100%);
  }
  .imd-parrafo {
    @include layout-paragraph($fs: 12px, $wd: 100%);
  }
  .imd-image-box {
    @include layout-image-box(100%);
  }
  .imd-play-button {
    justify-content: center;
    & svg {
      width: 20px;
      height: 20px;
    }
    & span {
      font-size: 14px;
    }
  }
}
