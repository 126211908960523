@mixin grid-circle-card($columns: repeat(4, 1fr), $gap: 1rem, $padding: $padding-layout) {
  display: grid;
  grid-template-columns: $columns;
  grid-row-gap: $gap;
  grid-column-gap: $gap;
  text-align: center;
  padding: $padding;
}

@mixin card-circle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin card-circle-image($sz: 100px) {
  width: $sz;
  height: $sz;
  background-color: $withe-color;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin svg-card-circle($sz: 50px) {
  width: $sz;
  height: $sz;
}

.imd-card-circle-container {
  @include grid-circle-card($gap: 3rem);

  & .imd-card-circle {
    @include card-circle-item;

    & .imd-card-circle-image {
      @include card-circle-image;

      & svg {
        @include svg-card-circle;
        fill: $secondary-color;
      }
    }

    & .imd-card-circle-title {
      font-size: 20px;
      text-transform: uppercase;
      color: $withe-color;
    }

    & .imd-card-circle-description {
      font-size: 14px;
      color: $withe-color;
      font-weight: 100;
    }
  }

  /*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
  @media (min-width: 1281px) {
    @include grid-circle-card($columns: repeat(4, 1fr), $padding: $padding-layout);
  }

  /*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

  @media (min-width: 1025px) and (max-width: 1280px) {
    @include grid-circle-card($columns: repeat(4, 1fr), $padding: $padding-layout);
  }

  /*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include grid-circle-card($columns: repeat(2, 1fr), $padding: $padding-layout / 3 0);
  }

  /*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    @include grid-circle-card($columns: repeat(4, 1fr), $padding: $padding-layout / 3 0);

    & .imd-card-circle {
      @include card-circle-item;

      & .imd-card-circle-image {
        @include card-circle-image($sz: 100px);

        & svg {
          @include svg-card-circle($sz: 50px);
        }
      }

      & .imd-card-circle-title {
        font-size: 14px;
        text-transform: uppercase;
        color: $withe-color;
      }

      & .imd-card-circle-description {
        font-size: 12px;
      }
    }
  }

  /*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

  @media (min-width: 481px) and (max-width: 767px) {
    @include grid-circle-card($columns: repeat(2, 1fr), $padding: $padding-layout / 3 0);

    & .imd-card-circle {
      @include card-circle-item;

      & .imd-card-circle-image {
        @include card-circle-image($sz: 100px);

        & svg {
          @include svg-card-circle($sz: 50px);
        }
      }

      & .imd-card-circle-title {
        font-size: 14px;
        text-transform: uppercase;
        color: $withe-color;
      }

      & .imd-card-circle-description {
        font-size: 12px;
      }
    }
  }

  /*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
  @media (min-width: 320px) and (max-width: 480px) {
    @include grid-circle-card($columns: repeat(2, 1fr), $padding: $padding-layout / 3 0);

    & .imd-card-circle {
      @include card-circle-item;

      & .imd-card-circle-image {
        @include card-circle-image($sz: 100px);

        & svg {
          @include svg-card-circle($sz: 50px);
        }
      }

      & .imd-card-circle-title {
        font-size: 14px;
        text-transform: uppercase;
        color: $withe-color;
      }

      & .imd-card-circle-description {
        font-size: 12px;
      }
    }
  }
}
