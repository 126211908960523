@import 'variables';

@mixin btn-theme($bnt-bg, $btn-color, $padding: 9px 20px, $fs: 14px, $ls: 1.5px) {
  background-color: $bnt-bg;
  border-radius: $border-radius;
  border: 0;
  color: $btn-color;
  padding: $padding;
  font-weight: 600;
  font-size: $fs;
  letter-spacing: $ls;
  overflow: hidden;
  text-decoration: none;
  margin: 0.5rem;
  cursor: pointer;
}

.imd-btn-default {
  @include btn-theme($grey-color, $withe-color);
}
.imd-btn-primary {
  @include btn-theme($primary-color, $withe-color);
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/

@media (min-width: 1025px) and (max-width: 1280px) {
}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media (min-width: 481px) and (max-width: 767px) {
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media (min-width: 320px) and (max-width: 480px) {
  .imd-btn-default {
    @include btn-theme($grey-color, $withe-color, 8px 15px, 12px, 1px);
  }
  .imd-btn-primary {
    @include btn-theme($primary-color, $withe-color, 8px 15px, 12px, 1px);
  }
}
