@import 'variables';
@mixin header-layout($padding) {
  height: $nav-size;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 0 $padding;
  font-family: $font-family;
  background-color: $withe-color;
}

@mixin flex-menu($f-direction, $a-items) {
  list-style: none;
  display: flex;
  flex-direction: $f-direction;
  align-items: $a-items;
  padding-left: 0;
}

@mixin flex-menu-collapse {
  position: absolute;
  top: $nav-size;
  right: 0;
  left: 0;
  margin: 0;
  background-color: $withe-color;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  max-height: 0;
}

.imd-header {
  @include header-layout($padding-layout);

  & .imd-header-logo {
    max-width: $nav-size / 0.5;
    height: auto;
    & img {
      width: 100%;
      height: 100%;
    }
  }

  & .imd-menu {
    @include flex-menu(row, center);
    margin: 0;
    & li {
      text-decoration: none;
      font-size: 14px;
      font-weight: 600;
      padding-right: 20px;
      &:last-child {
        padding-right: 0;
      }
      & a {
        &:active {
          color: $secondary-color !important;
        }
      }
    }
  }

  /*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

  @media (min-width: 1281px) {
    //CSS
    .imd-movile-menu-icon {
      display: none;
    }
  }

  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

  @media (min-width: 1025px) and (max-width: 1280px) {
    //CSS
    .imd-movile-menu-icon {
      display: none;
    }
  }

  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @include header-layout($padding-layout / 3);
    & .imd-menu {
      display: none;
    }
    .imd-movile-menu-icon {
      display: block;
      & input {
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked ~ .imd-menu-movile {
          height: auto;
          max-height: fit-content;
        }
      }

      & .imd-menu-movile {
        @include flex-menu(column, center);
        @include flex-menu-collapse;
        & li {
          text-decoration: none;
          font-size: 12px;
          font-weight: 600;
          padding-bottom: 20px;
          &:first-child {
            padding-top: 20px;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    //CSS
    @include header-layout($padding-layout / 3);
    & .imd-menu {
      display: none;
    }
    .imd-movile-menu-icon {
      display: block;
      & input {
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked ~ .imd-menu-movile {
          height: auto;
          max-height: fit-content;
        }
      }

      & .imd-menu-movile {
        @include flex-menu(column, center);
        @include flex-menu-collapse;
        & li {
          text-decoration: none;
          font-size: 12px;
          font-weight: 600;
          padding-bottom: 20px;
          &:first-child {
            padding-top: 20px;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

  @media (min-width: 481px) and (max-width: 767px) {
    @include header-layout($padding-layout / 3);
    & .imd-menu {
      display: none;
    }
    .imd-movile-menu-icon {
      display: block;
      & input {
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked ~ .imd-menu-movile {
          height: auto;
          max-height: fit-content;
        }
      }

      & .imd-menu-movile {
        @include flex-menu(column, center);
        @include flex-menu-collapse;
        & li {
          text-decoration: none;
          font-size: 12px;
          font-weight: 600;
          padding-bottom: 20px;
          &:first-child {
            padding-top: 20px;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }

  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

  @media (min-width: 320px) and (max-width: 480px) {
    @include header-layout($padding-layout / 3);
    & .imd-menu {
      display: none;
    }
    .imd-movile-menu-icon {
      display: block;
      & input {
        position: absolute;
        top: 0;
        visibility: hidden;
        &:checked ~ .imd-menu-movile {
          height: auto;
          max-height: fit-content;
        }
      }

      & .imd-menu-movile {
        @include flex-menu(column, center);
        @include flex-menu-collapse;
        & li {
          text-decoration: none;
          font-size: 12px;
          font-weight: 600;
          padding-bottom: 20px;
          &:first-child {
            padding-top: 20px;
          }
          &:last-child {
            padding-right: 0;
          }
          & a {
            &:active {
              color: $secondary-color;
            }
          }
        }
      }
    }
  }
}
